@font-face {
    font-family: Grindel;
    src: url(/fonts/Grinde.otf?1bb9848ec5bbdf5e8bb78ceb7a157fca);
  }
  .text-grindel {
    font-family: "Grindel";
    font-weight: 400;
  }
  
  .text-custom-red {
    color: #9a0002;
  }
  
  a.text-decoration-none {
    text-decoration: none !important;
  }
  
  body {
    overflow-x: hidden;
  }
  
  .icon-arrows {
    color: #9a0002;
    z-index: 1;
    font-size: 28px;
    margin-right: 4px;
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 1;
    transition: all 0.15s ease;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;
  }
  .carousel-control-prev :hover,
  .carousel-control-next :hover {
    opacity: 1;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
  
  .icon-carrousel-circle {
    color: #f6e1e1;
    font-size: 40px;
  }
  
  .mt-section {
    margin-top: 140px;
  }
  
  .mb-section {
    margin-bottom: 140px;
  }
  
  .hg-products{
    height: 200px;
  }
  
  /* ======================
  ---------SERVICES--------
  =========================*/
  .box-services {
    background: #9a0002;
    height: 150px;
  }
  
  .services {
    background: linear-gradient(180deg, #f6e1e1 40%, transparent 40%);
    height: auto;
    min-height: 300px;
  }
  
  .box-services-text {
    background-color: #f3f2f2;
    height: 150px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 5px 5px 10px #757575;
  }
  
  .border-gold {
    height: 20px;
    background: #b6aa70;
  }
  
  /* ======================
  ----------WORKS----------
  =========================*/
  .line-gold {
    margin-left: 30px;
    width: calc(100% - 60px);
  }
  
  .owl-carousel .owl-item img {
    padding: 0 30px;
  }
  
  .clients .owl-theme .owl-dots .owl-dot.active span,
  .clients .owl-theme .owl-dots .owl-dot:hover span {
    background: #d7b045 !important;
    height: 15px;
    width: 15px;
    margin-top: 40px;
  }
  
  .clients .owl-theme .owl-dots .owl-dot span {
    background: #d3d2d2 !important;
    height: 15px;
    width: 15px;
  }
  
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    margin-top: -100px;
  }
  
  /* ======================
  ---------WHATSAPP--------
  =========================*/
  a#wsp-widget img {
    margin: 0 !important;
    width: 60px !important;
  }
  
  a#wsp-widget {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1024;
    display: flex;
  }
  
  /* ======================
  ----------FOOTER---------
  =========================*/
  p > a.footer {
    color: #242323;
  }
  
  footer .section-1 {
    font-size: 20px;
    font-weight: 600;
  }
  
  .box-mision {
    overflow-y: auto;
    max-height: 600px;
  }
  
  .img-box-mision {
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
  
  .circle-right {
    width: 160px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999;
  }
  
  .circle-left {
    width: 160px;
    height: 160px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999;
  }
  
  .hr-title {
    width: 50%;
    margin-top: -140px;
    margin-left: 50vw;
  }
  
  .hr-title-left {
    width: 50%;
    margin-top: -140px;
  }
  
  .decoration-none {
    text-decoration: none;
  }
  
  .img-block {
    width: 100vw;
  }
  
  .text-prox {
    font-size: calc(2.365rem + 1.38vw);
  }
  
  .text-title-products {
    color: #680c00;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0px;
  }
  
  .title-description {
    color: #680c00;
    padding-right: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    text-align: right;
    text-transform: uppercase;
  }
  
  .title-define {
    color: #680c00;
    text-align: center;
    font-weight: 500;
    font-size: 2rem;
    text-align: left;
  }
  
  /* ======================
  ----------galery----------
  =========================*/
  
  #textil .owl-carousel img {
    height: 500px;
    object-fit: cover;
  }
  
  .box-galery {
    display: grid;
    grid-template-columns:repeat(3, 1fr);
    grid-gap: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .items-galery {
      width: 180px;
  }
  
  @media screen and (min-width: 992px) {
    .items-galery {
        width: 340px;
    }
  }
  
  @media screen and (min-width: 567px) and (max-width: 992px) {
    .mt-section {
      margin-top: 40px;
    }
    .services {
      min-height: 0;
    }
  }
  @media screen and (max-width: 778px) {
    #header .menu {
      background-color: #9a0002;
    }
    .box-galery {
      display: grid;
      grid-template-columns:repeat(3, 1fr);
      grid-gap: 10px;
    }
    
    .items-galery {
        max-width: 160px;
    }
  
    .title-description {
      color: #680c00;
      font-weight: bold;
      font-size: 2rem;
      text-align: center;
      text-transform: uppercase;
    }
    
    .title-define {
      color: #680c00;
      font-weight: 400;
      font-size: 2rem;
      text-align: center;
    }
  }
  @media screen and (max-width: 576px) {
    .circle-right {
      margin-left: -80px;
      margin-right: auto;
    }
    .circle-left {
      margin-left: auto;
      margin-right: -80px;
    }
    .hr-title {
      width: 100%;
      margin-top: -120px;
      margin-left: 0vw;
    }
    .hr-title-left {
      width: 100%;
      margin-top: -120px;
    }
    .mt-section {
      margin-top: 20px;
    }
    .services {
      min-height: 0;
    }
    .services .card .border-gold {
      height: 7px;
      background: #b6aa70;
    }
    .box-galery {
      display: grid;
      grid-template-columns:repeat(2, 1fr);
      grid-gap: 10px;
    }
    
    .items-galery {
        max-width: 140px;
    }
  
  }
  